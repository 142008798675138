import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { BsTelephone } from "react-icons/bs"
import { AiOutlineMail } from "react-icons/ai"
import { VscLocation } from "react-icons/vsc"
import { GrFacebook } from "react-icons/gr";
import { RiInstagramFill, RiTwitterXFill } from "react-icons/ri";

//import 'uikit/dist/css/uikit.css'

import "./footer.scss"


const Footer = () => {

    const data = useStaticQuery(graphql`
    {
        strapiGlobal {
            ContactDetails {
              Address
              Email
              Phone
            }
            SocialLinks{
                FacebookURL
                InstagramURL
                TwiterURL
            }
        }
        strapiFooter {
            Column1 {
              Title
              URL
            }
            Column2 {
              Title
              URL
            }
            Column3 {
              Title
              URL
            }
          }
    }

    `)

    const handleGtagFooterPhone = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'footer-phone');

        }
    }

    const handleGtagFooterEmail = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'footer-email');

        }
    }

    return (
        <>
            <section className="footer-section">
                <div className="footer-wrapper">

                    <div className="footer-grid uk-grid" data-uk-grid>
                        {
                            data.strapiFooter.Column1.length ?
                                <div className="uk-width-1-5@m uk-width-1-2@s footer-column  uk-text-left">
                                    <p id="footer-title">Our services</p>
                                    <ul>
                                        {
                                            data.strapiFooter.Column1.map((item, index) => {
                                                return (
                                                    <li key={index}><Link to={item.URL}>{item.Title}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                : ""
                        }
                        {
                            data.strapiFooter.Column2.length ?
                                <div className="uk-width-1-5@m uk-width-1-2@s second-footer-column footer-column uk-text-left">
                                    <p id="footer-title">About us</p>
                                    <ul>
                                        {
                                            data.strapiFooter.Column2.map((item, index) => {

                                                return (

                                                    <li key={index}><Link to={item.URL}>{item.Title}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                : ""
                        }
                        {
                            data.strapiFooter.Column3.length ?
                                <div className="uk-width-1-5@m uk-width-1-2@s footer-column footer-column uk-text-left" >
                                    <p id="footer-title">Help & Support</p>
                                    <ul>
                                        {
                                            data.strapiFooter.Column3.map((item, index) => {

                                                return (

                                                    <li key={index}><Link to={item.URL}>{item.Title}</Link></li>
                                                )
                                            })
                                        }
                                    </ul>

                                </div> : ""
                        }
                        <div className="uk-width-1-5@m uk-width-1-2@s fourth-column uk-text-left" >
                            <p id="footer-title">Get In Touch</p>
                            <ul>

                                {
                                    data.strapiGlobal.ContactDetails.Phone ?
                                        <li>
                                            <div onClick={() => handleGtagFooterPhone()}>
                                                <a href={`tel:${data.strapiGlobal.ContactDetails.Phone}`} className="location-phone" style={{ display: 'flex' }}><BsTelephone />{data.strapiGlobal.ContactDetails.Phone}</a>
                                            </div>
                                        </li>
                                        : ""

                                }
                                {
                                    data.strapiGlobal.ContactDetails.Email ?
                                        <li>
                                            <div onClick={() => handleGtagFooterEmail()}>
                                                <a href={`mailto:${data.strapiGlobal.ContactDetails.Email}`} className="location-email" style={{ display: 'flex' }}><AiOutlineMail /><div style={{ maxWidth: "50%" }}>{data.strapiGlobal.ContactDetails.Email}</div></a>
                                            </div>
                                        </li>
                                        : ""

                                }
                                {
                                    data.strapiGlobal.ContactDetails.Address ?
                                        <li>
                                            <div className="location-content" style={{ display: '-webkit-inline-box' }}>
                                                <VscLocation />
                                                <div className="location-address">
                                                    {/* {data.strapiGlobal.ContactDetails.Address} */}

                                                    <p>Tower Heating Ltd</p>
                                                    <p>47A Liverpool Road,</p>
                                                    <p>Formby,</p>
                                                    <p>Liverpool,</p>
                                                    <p>L37 6BT</p>

                                                </div>
                                            </div>
                                        </li>
                                        : ""
                                }


                            </ul>
                        </div>

                        <div className="uk-width-1-5@m uk-width-1-2@s latest-footer-column  uk-text-left" >
                            {/* <p id="footer-title">Follow Us</p> */}
                            <div className="social-links">
                                {
                                    data.strapiGlobal.SocialLinks.FacebookURL ? <a href={data.strapiGlobal.SocialLinks.FacebookURL} aria-label="Facebook-Tower Heating"><GrFacebook /></a> : ""

                                }
                                {
                                    data.strapiGlobal.SocialLinks.InstagramURL ? <a href={data.strapiGlobal.SocialLinks.InstagramURL} aria-label="Instagram-Tower Heating"><RiInstagramFill /></a> : ""

                                }
                                {
                                    data.strapiGlobal.SocialLinks.TwiterURL ? <a href={data.strapiGlobal.SocialLinks.TwiterURL} aria-label="Twitter-Tower Heating"><RiTwitterXFill /></a> : ""

                                }

                            </div>
                            <div className="socials-advert">
                                <p>Here for you:</p>
                                <p>8am - 8pm</p>
                                <p>365 days a year</p>
                            </div>

                        </div>

                    </div>

                    {/* <div className="footer__copyright">
                    </div> */}


                    {/* <div class="ctct-inline-form" data-form-id="681f50cc-8af1-42a5-9e72-67c4f94bd173"></div>
                    
                    <script>
                        var _ctct_m = "e7ec720b401477d5e5a77975e70f1fd1";
                    </script>
                    <script id="signupScript" src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js" async defer></script> */}


                </div>


            </section>
        </>
    )
}

export default Footer