import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import "../../styles/variables.scss"


function Seo({ description, lang, meta, title }) {
  const { site, strapiGlobal } = useStaticQuery(
    graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
            }
          }

          strapiGlobal {
            defaultSeo {
              metaDescription
              metaTitle
            }
            siteName
          }
        }
      `
  )


  const metaDescription = description || strapiGlobal.defaultSeo.metaDescription
  var metaTitle = title || strapiGlobal.defaultSeo.metaDescription

  if (title !== "") {
    metaTitle = strapiGlobal.defaultSeo.metaTitle + " | " + title
  }


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={[
        {
          rel: "preconnect",
          href:
            "https://fonts.gstatic.com",
        },
        {
          rel: "preconnect",
          href:
            "https://cdn.jsdelivr.net",
        },
        {
          rel: "preconnect",
          href:
            "https://fonts.googleapis.com/",
        },
        {
          rel: "preload",
          href: "https://fonts.googleapis.com/css?family=Roboto",
          as: "style"
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap",
          // async: true
        },
        // {
        //   rel: "stylesheet",
        //   href:
        //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/css/uikit.min.css",
        // }

      ]}
      script={[
        // {
        //   src:
        //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/js/uikit.min.js",
        // },
        // {
        //   src:
        //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/js/uikit-icons.min.js",
        // },
        // {
        //   src:
        //     "https://sf.surefiresoftware.co.uk/surequote2/surequote.js",
        // }
      ]}


    >
      <meta key="key-description" name="description" content={metaDescription} />
      <meta key="key-ogtitle" name="og:title" content={metaTitle} />
      <meta key="key-ogdescription" name="og:description" content={metaDescription} />
      <meta key="key-ogtype" name="og:type" content="website" />
      <meta key="key-description" name="twitter:card" content="summary" />
      <meta key="key-twittercreateor" name="twitter:creator" content={site.siteMetadata.author} />
      <meta key="key-twittertitle" name="twitter:title" content={metaTitle} />
      <meta key="key-twitterdesc" name="twitter:description" content={metaDescription} />
      {/** List of meta properties coming from strapi settings that will apply globally across all pages */

        strapiGlobal.MetaTags?.map((item, index) => {
          return (
            <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
          )
        })

      }

    </Helmet>
  )
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  title: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
