import React from 'react';
import './button.scss';
import { Link } from "gatsby";


const Button = ({isSubmit="false", btnType, id, label, clickAction, additionalClass="", href, toggle}) =>{
    return (
        <>
            {
                href ? <Link id={id} className={`button button-${btnType} ${additionalClass}`} to={href}>{label}</Link>

                    :  <button id={id} uk-toggle={toggle} type={isSubmit == "true" ? "submit" : "button"} className={`button button-${btnType} ${additionalClass}`} onClick={clickAction}>{label}</button>


            }
        </>
    )
}   

export default Button;