// import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server"
import PropTypes from "prop-types"
import React from "react"

import "../styles/global.scss"
import Seo from "../components/seo/seo.js"
import Nav from "../components/nav/nav.js"
import Footer from "../components/footer/footer.js"

// import 'uikit/dist/css/uikit.css'
import UIkit from 'uikit'


const Layout = ({ children, pageData, SEOTitle, SEODescription, footerDisclaimer, darkThemeNav, meta}) => {



    return(
        <>
            {/* SEO section */}
            <Seo title={SEOTitle} description={SEODescription} meta={meta}></Seo>
            <Nav forcedDarkTheme={darkThemeNav}></Nav>

            <main>{children}</main>

            {/* footer section */}
            <Footer disclaimer={footerDisclaimer}></Footer>
        </>
    )
    
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
