import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { BsTelephone } from "react-icons/bs"
//import 'uikit/dist/css/uikit.css'
import "./nav.scss"
import navImage from "../../assets/Tower_Heating_Logo.png";
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../button/button"
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { FaRegEnvelope } from "react-icons/fa"



const LinkList = ({ megaMenuItem, color, menuDropdown }) => {

    const [hover, setHover] = useState(false);

    return (

        <Link to={menuDropdown.Link} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={hover ? { borderBottom: `3px solid ${color}` } : {}}>{megaMenuItem.Title}</Link>


    )

}
const MobileDropdown = ({ item }) => {

    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    return (
        <li>
            <h5 className="border-bottom" onClick={() => setOpenMobileMenu(!openMobileMenu)}>{openMobileMenu ? <AiOutlineUp /> : <AiOutlineDown />}{item.Title}</h5>
            {
                <div className="header-submenu-mobil-menu" style={openMobileMenu ? { display: 'block' } : { display: 'none' }}>
                    <ul className="mobile-dropdown">
                        {
                            item.SubMenu.map((item, subMenuIndex) => {
                                return (

                                    <li key={`subMenuDropdownMobile-` + subMenuIndex}>
                                        <Link className="border-bottom submenu-dropdown-item" to={item.URL}>{item.Title}</Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
        </li>
    )

}

const Nav = ({ forcedDarkTheme }) => {

    const data = useStaticQuery(graphql`
    {
        strapiGlobal {
            ContactDetails {
              Address
              Email
              Phone
            }
            defaultSeo{
                shareImage{
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
        }
        strapiMenu {
            HeaderMenu {
              id
              Title
              URL
              SubMenu {
                Title
                URL
                Icon {
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
              }
            }
            MegaMenuList {
                
                Color
                Title
                URL
                Type
                DisplayOnMobile
                DisplayOnServiceDropdown
            }
        }
        strapiMegaMenu {
            MegaMenuDropdown {
              
              URL
              Image{
                localFile{
                    childImageSharp{
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                        )
                    }
                }
              }
              MegaMenuItem {
                
                Subtitle
                Title
                URL
                Image{
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
              }
              Link
            }
        }
    }

    `)

    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    const handleGtagNavPhone = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'nav-phone');

        }
    }

    const handleGtagInstantQuote = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'instant-quote-button');

        }
    }

    const handleGtagModalInstantQuote = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'btn-from-instant-quote-modal');

        }
    }

    const handleGtagInstantQuoteMobile = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'instant-quote-mobile-menu');

        }
    }


    return (
        <div>
            <div className={" navigationBar"}>
                <div className="uk-sticky" data-uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top:100">
                    <div className="nav-top-container">
                        <nav className="nav-options uk-navbar-container uk-position-top uk-navbar-transparent nav-top" data-uk-navbar>
                            <div className="uk-navbar-left navbar-left" >
                                <ul className="uk-navbar-nav">
                                    <li>
                                        {
                                            data.strapiGlobal.ContactDetails.Phone ?
                                                <div onClick={() => handleGtagNavPhone()}>
                                                    <a href={`tel:${data.strapiGlobal.ContactDetails.Phone.replace(" ","")}`} className="phone-link"><BsTelephone />{data.strapiGlobal.ContactDetails.Phone}</a>
                                                </div>
                                                : ""

                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="uk-navbar-right uk-visible@l">
                                <ul className="uk-navbar-nav nav-list-links">
                                    {
                                        data.strapiMenu.HeaderMenu ? data.strapiMenu.HeaderMenu.map((item, index) => {

                                            return (

                                                <li key={`headerMenu-` + item.id} className={`headerMenu-` + index}>
                                                    {
                                                        item.URL === '#0' ?
                                                            <>
                                                                <span className="submenu-title">{item.Title}</span>
                                                                {
                                                                    item.SubMenu.length > 0 ?
                                                                        <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown="offset: -15; delay-hide: 50">
                                                                            <ul className="uk-nav uk-navbar-dropdown-nav submenu-list">
                                                                                {
                                                                                    item.SubMenu.map((subMenuItem, index) => {
                                                                                        return (

                                                                                            <li key={`subMenu-` + Math.random()} className={`subMenu-` + Math.random()}>
                                                                                                {(subMenuItem.Title !== "Our History" && subMenuItem.Title !== "FAQs") ? <hr /> : ""}
                                                                                                <div className="display-flex">
                                                                                                    <GatsbyImage image={subMenuItem.Icon.localFile.childImageSharp.gatsbyImageData} alt="icon" className="item-icon"></GatsbyImage>

                                                                                                    <Link to={`${subMenuItem.URL}`}>{subMenuItem.Title}</Link>
                                                                                                </div>

                                                                                            </li>
                                                                                        )

                                                                                    })

                                                                                }

                                                                            </ul>
                                                                        </div> : ""

                                                                }
                                                            </>
                                                            :
                                                            item.Title === "Contact us" ?
                                                                <span><Link className="nav-link" key={`nav-link_` + index} to={`${item.URL}`}><FaRegEnvelope className="contact-icon" /> {item.Title}</Link></span>
                                                                :
                                                                <span><Link className="nav-link" key={`nav-link_` + index} to={`${item.URL}`}>{item.Title}</Link></span>
                                                    }
                                                </li>
                                            )
                                        }) : ""

                                    }

                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div className="nav-bottom-container">
                        <nav className="nav-options uk-navbar-container uk-position-top uk-navbar-transparent nav-bottom" data-uk-navbar>
                            <div className="uk-navbar-left navbar-left" style={{ paddingLeft: '0px' }}>
                                <ul className="uk-navbar-nav">
                                    <li>
                                        {/* {data.strapiSiteSettings.LogoLight.localFile.childImageSharp ?
                                        <a href="/"><GatsbyImage image={data.strapiSiteSettings.LogoLight.localFile.childImageSharp.gatsbyImageData} alt="The insurers logo light" width="200" height="39"></GatsbyImage></a>
                                        : <a href="/"><img src={data.strapiSiteSettings.LogoLight.url} alt="The insurers logo light" width="200" height="39" /></a>
                                    } */}
                                        <Link to="/" aria-label="Logo Tower Heating"><img src={navImage} alt=""></img></Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="uk-navbar-right uk-visible@l">
                                <ul className="uk-navbar-nav nav-list-links">
                                    {
                                        data.strapiMenu.MegaMenuList.length > 0 ?
                                            data.strapiMenu.MegaMenuList.map((megaMenuItem, index) => {

                                                var menuDropdown = data.strapiMegaMenu.MegaMenuDropdown.find(menuItem => menuItem.URL === megaMenuItem.URL)
                                                var className;
                                                var color;
                                                switch (megaMenuItem.Color) {
                                                    case "Blue":
                                                        className = "second-color";
                                                        color = "#63aff4";
                                                        break;
                                                    case "Green":
                                                        className = "main-color";
                                                        color = "#3ba950";
                                                        break;
                                                    case "Orange":
                                                        className = "third-color";
                                                        color = "#e4ab3b";

                                                }
                                                switch (megaMenuItem.Type) {

                                                    case "Link":
                                                        return (
                                                            <>
                                                                {
                                                                    megaMenuItem.URL.charAt(0) === '#' ?
                                                                        <li key={`megaMenu-` + index} className={`megaMenu-` + index}>
                                                                            <LinkList color={color} megaMenuItem={megaMenuItem} menuDropdown={menuDropdown}></LinkList>
                                                                            {
                                                                                menuDropdown ?
                                                                                    <div className="uk-navbar-dropdown full-width-submenu-container" data-uk-dropdown="offset: 10; delay-hide: 50" style={{ borderBottom: `5px solid ${color}` }}>
                                                                                        <div className="submenu-container__display-flex">
                                                                                            <div className="submenu-left-column">
                                                                                                <ul className="submenu-left-column__list">
                                                                                                    {
                                                                                                        menuDropdown.MegaMenuItem.map((item, newIndex) => {

                                                                                                            return (

                                                                                                                <li key={`menuDropdown-` + Math.random()} className={`menuDropdown-` + Math.random()}>

                                                                                                                    <Link to={`${item.URL}`}>
                                                                                                                        <div className="item-megamenu">
                                                                                                                            <GatsbyImage image={item?.Image?.localFile.childImageSharp.gatsbyImageData} alt="Image" width="50" height="50"></GatsbyImage>
                                                                                                                            <div className="item-megamenu-description">
                                                                                                                                <h4 className={`${className}`}>{item.Title}</h4>
                                                                                                                                <p>{item.Subtitle}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Link>
                                                                                                                </li>
                                                                                                            )

                                                                                                        })
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                            <div className="submenu-right-column">
                                                                                                <Link to={menuDropdown.Link}><GatsbyImage image={menuDropdown.Image.localFile.childImageSharp.gatsbyImageData} alt="Image" ></GatsbyImage></Link>
                                                                                                {/* {console.log(menuDropdown.Link,"linkkkkkkk")} */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : ""




                                                                            }
                                                                        </li>

                                                                        : <Link to={`${megaMenuItem.URL}`} key={`megaMenuItem-` + index} className={`megaMenuItem-` + index}>{megaMenuItem.Title}</Link>
                                                                }
                                                            </>
                                                        )
                                                    case "Button":
                                                        return (

                                                            megaMenuItem.URL.charAt(0) === '#' ?
                                                                <li key={`megaMenuButton-` + index} className={`megaMenuButton-` + index}>
                                                                    <Button
                                                                        onClick={() => megaMenuItem.Title.includes("Instant Boiler Quote") ? handleGtagInstantQuote() : ""}
                                                                        href={megaMenuItem.Title.includes("Instant Boiler Quote") ? menuDropdown.Link : megaMenuItem.URL}
                                                                        label={megaMenuItem.Title}
                                                                        btnType={"primary"}
                                                                        id="quote-navbar-btn"
                                                                    />
                                                                    {
                                                                        menuDropdown ?

                                                                            <div key={`menuDrop-` + index} className="uk-navbar-dropdown full-width-submenu-container" data-uk-dropdown="offset: 10; delay-hide: 50" style={{ borderBottom: `5px solid ${color}` }}>
                                                                                <div className="submenu-container__display-flex">
                                                                                    <div className="submenu-left-column">
                                                                                        <GatsbyImage image={menuDropdown?.Image.localFile.childImageSharp.gatsbyImageData} alt="Image" ></GatsbyImage>

                                                                                    </div>
                                                                                    <div className="button-submenu-right-column">
                                                                                        <h1 className={`${className}`}>{menuDropdown.MegaMenuItem[0].Title}</h1>
                                                                                        <h4>{menuDropdown.MegaMenuItem[0].Subtitle}</h4>
                                                                                        <GatsbyImage image={menuDropdown?.MegaMenuItem[0].Image.localFile.childImageSharp.gatsbyImageData} alt="Image" ></GatsbyImage>
                                                                                        <div className="margin-top" onClick={() => handleGtagModalInstantQuote()}>
                                                                                            <Link to="/instant-boiler-quote" className="btn-accent">Get your price online</Link>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> : ""

                                                                    }
                                                                </li>

                                                                : ""
                                                        )
                                                }


                                            }) : ""

                                    }


                                </ul>

                            </div>


                            <div className="uk-navbar-right uk-hidden@l">
                                <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn" data-uk-toggle="target: #offcanvas-menu">
                                    <span className="uk-margin-small-right">Menu</span>
                                    <span data-uk-navbar-toggle-icon>&nbsp;</span>
                                </a>
                            </div>

                            <div id="offcanvas-menu" data-uk-offcanvas="overlay: true; flip: true; mode: reveal" className="uk-offcanvas">
                                <div className="uk-offcanvas-bar uk-flex uk-flex-column">

                                    <button className="uk-offcanvas-close" type="button" data-uk-close aria-label="close button"></button>
                                    <div className="menu-mobile-container">
                                        <GatsbyImage image={data.strapiGlobal.defaultSeo.shareImage?.localFile.childImageSharp.gatsbyImageData} alt="Image" ></GatsbyImage>
                                        <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical uk-nav-parent-icon menu-mobile-list" data-uk-nav="multiple: true">
                                            <li>
                                                <h5 className="border-bottom" onClick={() => setOpenMobileMenu(!openMobileMenu)}>{openMobileMenu ? <AiOutlineUp /> : <AiOutlineDown />}Our services</h5>
                                                {
                                                    data.strapiMenu.MegaMenuList ?
                                                        <div className="submenu-dropdown" style={openMobileMenu ? { display: 'block' } : { display: 'none' }}>
                                                            {
                                                                data.strapiMenu.MegaMenuList.map((item, index) => {

                                                                    return (

                                                                        item.DisplayOnMobile && item.DisplayOnServiceDropdown ?

                                                                            <Link key={`display-` + index} className="border-bottom submenu-dropdown-item" to={`/${item.URL.split('#')[1]}`}>{item.Title}</Link>

                                                                            : ""
                                                                    )
                                                                }
                                                                )
                                                            }

                                                        </div>
                                                        : ""
                                                }


                                            </li>
                                            {
                                                data.strapiMenu.MegaMenuList ?
                                                    data.strapiMenu.MegaMenuList.map((item, index) => {

                                                        return (

                                                            item.DisplayOnMobile && !item.DisplayOnServiceDropdown ?
                                                                <li key={`displayMob-` + index} className={`displayMob-` + index}>
                                                                    <Link
                                                                        onClick={() => item.Title.includes("Instant") ? handleGtagInstantQuoteMobile() : ""}
                                                                        className="border-bottom mobile-link font-bold"
                                                                        to={`/${item.URL.split('#')[1]}`}
                                                                    >{item.Title}
                                                                    </Link></li> : ""

                                                        )
                                                    })
                                                    : ""
                                            }
                                            {
                                                data.strapiMenu.HeaderMenu ? data.strapiMenu.HeaderMenu.map((item, index) => {

                                                    return (
                                                        item.SubMenu.length ?

                                                            <MobileDropdown item={item} type={"headerMenu"} key={`mobileDropdown-` + index} />
                                                            :
                                                            <li key={`subMenuMobileDropdown-` + index} className={`subMenuMobileDropdown-` + index}>
                                                                <Link className="border-bottom mobile-link font-bold" to={item.URL}>{item.Title}</Link>
                                                            </li>
                                                    )
                                                }) : ""
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Nav